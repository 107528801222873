@use 'themes';
@use 'diva-cloud-theme' as diva;

//Set default font family
* {
  font-family: "Open Sans", "Noto Sans JP";
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-size: 14px;
  color: #443f5f;
}
h1,
h2,
h3 {
  margin: 0;
  font-size: 16px !important;
  margin-bottom: 16px;
}

.mat-drawer-content {
  margin-left: 80px;
}

.mat-drawer-opened {
  + .mat-drawer-content {
    margin-left: 290px;
  }
}

/*Custom css for components generated*/
.myPanelClass {
  margin: 25px 0px;
}

.snakbar-warn {
  @include diva.snakbar-warn;
}

.snakbar-error {
  @include diva.snakbar-error;
}

.snakbar-success {
  @include diva.snakbar-success;
}
.mat-mdc-unelevated-button.mat-primary {
  @include diva.regular-button;
}

.mat-mdc-unelevated-button.mat-primary:disabled {
  @include diva.regular-button-disabled;
}

.mat-mdc-outlined-button.mat-primary {
  @include diva.regular-button-outline;
}

.mat-mdc-outlined-button.mat-primary:disabled {
  @include diva.regular-button-outline-disabled;
}

.mat-mdc-outlined-button.cancel {
  @include diva.cancel-button;
}

.mat-mdc-unelevated-button.mat-warn {
  @include diva.delete-button;
}

.mat-mdc-checkbox.mat-accent {
  @include diva.checkbox;
  --mdc-checkbox-selected-checkmark-color: #337ab7;
  --mdc-checkbox-selected-focus-icon-color: #d8d8d8;
  --mdc-checkbox-selected-hover-icon-color: #d8d8d8;
  --mdc-checkbox-selected-icon-color: #d8d8d8;
  --mdc-checkbox-selected-pressed-icon-color: #d8d8d8;
  --mdc-checkbox-unselected-icon-color: #d8d8d8;
  --mdc-checkbox-unselected-pressed-icon-color: #d8d8d8;
  --mdc-checkbox-unselected-focus-icon-color: #d8d8d8;
  --mdc-checkbox-unselected-hover-icon-color: #d8d8d8;

  .mdc-checkbox {
    &:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
      background-color: transparent;
    }
  }
}

.mat-icon {
  @include diva.mat-icon-overflow;
}

button.mat-mdc-button>.mat-icon,
button.mat-mdc-outlined-button>.mat-icon {
  margin: 0;
  font-size: 24px;
  height: 24px;
  width: 24px;
}

.mat-mdc-input-element {
  height: 18px;
}

/*Angular Material Table*/
.mattable {
  height: auto;
  border-radius: 4px 4px 0px 0px;
}

.mattable .mat-mdc-header-row {
  min-height: 50px;
  height: fit-content;
  background-color: #205a85;
  border-radius: 4px 4px 0px 0px;
}

.mattable .mat-mdc-row {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}

.mattable .mat-mdc-header-cell {
  border-bottom: none;
  background-color: #205a85;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px 4px 0px 0px;
  box-sizing: content-box;
}

.mattable .mat-mdc-cell {
  border-right: none;
  background-color: #fff;
  color: #443f5f;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: normal;
  word-break: break-word;
  cursor: pointer;
  border-bottom: none;
  box-sizing: content-box;
}

.mattable .mat-mdc-row.row-selected {
  background: #f1f1f1 !important;
  color: #443f5f !important;

  .mat-mdc-cell {
    background: #f1f1f1 !important;
    color: #443f5f !important;
  }
}

.mattable.no-row-selection .mat-mdc-row:hover {
  background: none !important;
  cursor: auto;

  .mat-mdc-cell {
    background: none !important;
    cursor: auto;
  }
}

.mattable .mat-sort-header[aria-sort="ascending"]  .mat-sort-header-arrow {
  position: relative;
  display: inline-block;
  border: none;
  width: 20px;
  height: 20px;
  background: url(/assets/icons/up-chevron.svg);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: 5px;
}

.mattable .mat-sort-header[aria-sort="descending"]  .mat-sort-header-arrow {
  position: relative;
  display: inline-block;
  border: none;
  width: 20px;
  height: 20px;
  background: url(/assets/icons/up-chevron.svg);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: 5px 3px;
  transform: rotate(180deg) !important;
}

.mattable .mat-sort-header-stem,
.mattable .mat-sort-header-indicator,
.mattable .mat-sort-header-left,
.mattable .mat-sort-header-right,
.mattable .mat-sort-header-pointer-middle {
  display: none;
}

mat-form-field {
  &.mat-focused {
    .mat-mdc-text-field-wrapper {
      .mdc-line-ripple::after {
        @include diva.themifyNoHostContext(diva.$themes) {
          border-bottom-color: diva.themed("diva-blue");
        }
      }
    }
  }

  .mat-mdc-text-field-wrapper {
    padding: 0px;
    background-color: transparent !important;

    .mat-mdc-input-element {
      position: absolute;
      bottom: 6px;

      height: 22px;
      font-size: 16px;

      @include diva.themifyNoHostContext(diva.$themes) {
        caret-color: diva.themed("diva-navy");
      }

      @include diva.themifyNoHostContext(diva.$themes) {
        color: diva.themed("diva-navy");
      }
    }

    .mdc-line-ripple::after {
      border-bottom-color: transparent;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }

    .mat-mdc-form-field-required-marker {
      color: #f44336;
    }

    // when a form has a valid value
    &:not(.mdc-text-field--invalid) {
      .mdc-floating-label {
        @include diva.themifyNoHostContext(diva.$themes) {
          color: diva.themed("diva-navy");
        }
      }

      &:hover .mdc-line-ripple::before {
        @include diva.themifyNoHostContext(diva.$themes) {
          border-bottom-color: diva.themed("diva-dark-blue");
        }
      }

      .mdc-line-ripple::before {
        @include diva.themifyNoHostContext(diva.$themes) {
          border-bottom-color: diva.themed("diva-dark-blue");
        }
      }
    }

    // when a form has invalid value
    &.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
      .mdc-floating-label {
        @include diva.themifyNoHostContext(diva.$themes) {
          color: diva.themed("diva-error-red");
        }

        &:not(.mdc-floating-label--float-above) {
          @include diva.themifyNoHostContext(diva.$themes) {
            color: diva.themed("diva-navy");
          }
        }
      }

      .mdc-line-ripple::after {
        @include diva.themifyNoHostContext(diva.$themes) {
          border-bottom-color: diva.themed("diva-error-red");
        }
      }

      .mdc-line-ripple::before {
        @include diva.themifyNoHostContext(diva.$themes) {
          border-bottom-color: diva.themed("diva-error-red");
        }
      }
    }
  }

  .mat-mdc-form-field-error-wrapper {
    padding-left: 0;
    padding-top: 2px;

    .mat-mdc-form-field-error {
      @include diva.themifyNoHostContext(diva.$themes) {
        color: diva.themed("diva-error-red");
      }
    }
  }

  // To remove unexpected space
  .mat-mdc-form-field-bottom-align::before {
    display: inline;
  }
}

.list-loading-spinner {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}

.list-loading-spinner circle {
  stroke: #379BE5;
}
