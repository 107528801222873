@use "@angular/material" as mat;

$diva-cloud-typography-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      use-system-variables: true,
    ),
    typography: (
      use-system-variables: true,
    )
  )
);

@include mat.core();
html {
  @include mat.all-component-themes($diva-cloud-typography-theme);
  @include mat.system-level-colors($diva-cloud-typography-theme);
  @include mat.system-level-typography($diva-cloud-typography-theme);
}

:root {
  --sys-body-large-font: "Open Sans", "Noto Sans JP";
  --sys-body-large-line-height: normal;
  --sys-body-large-size: 14px;
  --sys-body-large-tracking: normal;
  --sys-body-medium-font: "Open Sans", "Noto Sans JP";
  --sys-body-medium-line-height: normal;
  --sys-body-medium-size: 16px;
  --sys-body-medium-tracking: normal;
  --sys-body-small-font: "Open Sans", "Noto Sans JP";
  --sys-body-small-line-height: normal;
  --sys-body-small-tracking: normal;
  --mat-form-field-subscript-text-weight: 700;
  --mdc-text-button-label-text-color: #000000;
  --mat-text-button-state-layer-color: #000000;
  --mat-text-button-ripple-color: #0000001a;
  --sys-title-small-font: "Open Sans", "Noto Sans JP";
  --sys-title-small-line-height: normal;
  --sys-title-small-size: 14px;
  --sys-title-small-weight: 400;
  --sys-title-small-tracking: normal;
  --sys-label-large-font: "Open Sans", "Noto Sans JP";
  --sys-label-large-line-height: normal;
  --sys-label-large-size: 14px;
  --sys-label-large-weight: 400;
  --sys-label-large-tracking: normal;
  --sys-headline-small-font: "Open Sans", "Noto Sans JP";
  --sys-headline-small-line-height: 32px;
  --sys-headline-small-weight: 500;
  --sys-headline-small-tracking: 0125em;
  --sys-on-surface: #000000de;
  --sys-on-surface-variant: #00000099;
  --sys-surface: #ffffff;
  --sys-outline: #0000001f;
  --sys-surface-container: #ffffff;
  --mdc-dialog-subhead-tracking: .0125em;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-padding: 8px;
  --mdc-filled-button-container-shape: 4px;
  --mdc-text-button-container-shape: 4px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-hover-state-layer-opacity: .04;
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-container-shape: 0;
  --mat-select-panel-background-color: white;
  --mat-app-elevation-shadow-level-2: 0 5px 5px -3px #0003,0 8px 10px 1px #00000024,0 3px 14px 2px #0000001f;
}
